import useModal from "./useModal";
import apiCodeObject from "../error/errorType";
import { receiptTypeAtom } from '../state/headerAtom';
import { useRecoilState } from 'recoil';
import useToggle from '../hook/useToggle';

const useErrorModal = () => {
    const { showModal, closeModal } = useModal();
    const [toggle, changeType] = useToggle();
    const receiptType = useRecoilState(receiptTypeAtom)[0];

    const openErrorModal = (apiCode) => {
        showModal({
            modalType: 'ERROR',
            modalProps: {
                content: apiCodeObject[apiCode],
                evt: () => {
                    closeModal();
                    // if (errorTypeCode === 'Auth-0006') {
                    //     if (receiptType === 'corporation' && toggle === 'corporation') { 
                    //         window.location.reload();
                    //         changeType(0); 
                    //     }
                    // } else {
                    //     closeModal();
                    // }
                }
            }
        });
        
    }
    return { openErrorModal }
}

export default useErrorModal;