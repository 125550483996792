import useNavigation from '../../hook/useNavigation';
import { useEffect, useState } from 'react';
import './member.css';
import PopupPage from './PopupPage'; // 팝업 페이지 import
import Header from '../../components/header/Header';
import { memberApi } from '../../services/memberservice/memberapi';


const ChangePw = () => {
    const navigate = useNavigation();
    const [infoTxt, setInfoTxt] = useState({title:null, txt:null, btnTex:null, spantag:null})
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [passCheckResult, setPassCheckResult] = useState({ isCheck: false, eqCheck:false,  msg: "" });
    const [debouncedPassword, setDebouncedPassword] = useState("");

    const [inputValidate, setInputValidate] = useState("");
   
    const onChangValdate = (e) => {
        if (e.target.id === "nowPassword"){
            setPassword(e.target.value)
        }else if(e.target.id === "password"){
            setNewPassword(e.target.value);
        } else if(e.target.id === "passwordCheck"){
            setPasswordCheck(e.target.value);
        }
    }
      
    const closePopup = () => {
        setPopupOpen(false);
        navigate.goPath("/");
    };

    const changePwClick = () => {
        if(passCheckResult.isCheck && passCheckResult.eqCheck){
            memberApi.changePw({password,newPassword})
            .then(({data})=>{
                let {data:result,message} = data;
                if(result){
                    setInfoTxt({
                        title : '비밀번호 변경',
                        txt : data.message,
                        btnTex : "홈으로",
                        spantag : '',
                    })
                    openPopup();
                }else{
                    setInputValidate(message)
                }
            })
        }else{
            setInputValidate("비밀번호를 확인해 주세요.")
        }
    }
   
    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };

        
    const DEBOUNCE_TIME = 200; // input에 200ms간 입력이 없으면 입력을 완료한 것으로 간주 

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedPassword(newPassword);
        }, DEBOUNCE_TIME); // 새로운 타이머 설정

        return () => clearTimeout(debounce); // 이전 타이머 취소하는 클린업 함수
    }, [newPassword]);

    useEffect(() => {
        if(debouncedPassword.length > 0){
            memberApi.checkPass(newPassword)
                .then(({ data: res }) => {
                    let { code, msg } = res;
                    setInputValidate(msg)
                    setPassCheckResult({ ...passCheckResult, isCheck: code === 'PASS000' })
                })
                .catch(err => {
                    setInputValidate("비밀번호 검증중 오류가 발생했습니다.")
                })
        }
    }, [debouncedPassword]); // debouncedSearchValue가 변경될 때마다 api 호출

    useEffect(() => {
        if (passCheckResult.isCheck && passwordCheck.length > 0) {
            setPassCheckResult({...passCheckResult, eqCheck : newPassword === passwordCheck})
            if (newPassword !== passwordCheck) {
                setInputValidate("비밀번호가 일치하지 않습니다.")
            } else {
                setInputValidate("비밀번호가 동일합니다.")
            }
        }else{
            setPassCheckResult({...passCheckResult, eqCheck : false})
        }
    }, [newPassword, passwordCheck, passCheckResult.isCheck])

    return(
        <div>
            <div>
                <Header />
            </div>
            <div className='main_body'>
                <div className="main_div1">
                    <div>   
                        <h1>비밀번호 변경</h1>
                    </div>
                
                    <div className="main_div2">
                        <input placeholder="현재 비밀번호"  id="nowPassword" type="password"  onChange={onChangValdate} />
                        <input placeholder="새로운 비밀번호"  id="password" type="password"  onChange={onChangValdate} />
                        <input placeholder="비밀번호 확인" onChange={onChangValdate} id="passwordCheck" type="password"/>
                    </div>
                    <p className='valid'> {inputValidate} </p>
                    <button className="button" onClick={changePwClick}>비밀번호 변경</button>
                </div>    
            </div>
        
            {isPopupOpen && 
                <PopupPage 
                    infoTxt={infoTxt}
                    onClose={closePopup} //팝업이 닫힐 때
                />
            }
        
        </div>
    );
};

export default ChangePw;