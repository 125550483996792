import { useNavigate } from 'react-router-dom'

const useNavigation = () => {
    const navigate = useNavigate();

    const goPath = (path) => {
        navigate(path);
    }

    return {
        goPath
    }
}

export default useNavigation;