import { atom } from 'recoil';

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    // setSelf -> Callbacks to set or reset the value of the atom.
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
	
    // onSet -> Subscribe to changes in the atom value.
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const memberInfoAtom = atom({
    key: 'memberInfo',
    default: {
        isLogin:false,
        accessToken:null,
        refreshToken:null,
        email:null,
        name:null
    },
    effects: [localStorageEffect('memberInfo')]
});