import ClipLoader from "react-spinners/ClipLoader";

const Loading = () => {
    return (
        <div className='h-full w-full'>
            <div className='bg-black opacity-20 fixed top-0 bottom-0 left-0 right-0'></div>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            
                <ClipLoader
                    color={`#00A572`}
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
        
    )
}

export default Loading;