import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../../components/header/Header';
import { useRecoilState } from 'recoil';
import { headerTitleProps } from '../../components/header/headerProps';

import receiptApi from '../../services/receiptService/receiptApi';
import { utilService } from '../../util/utilService';
import useNavigation from '../../hook/useNavigation';
import { deleteAtom } from '../../state/HomeAtom';
import './Receipt.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const Detail = () => {
    const location = useLocation();
    const navigate = useNavigation();

    const [receiptData, setReceiptData] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const [image, setImage] = useState(false);
    const [receiptId, setReceiptId] = useState();
    const [editable, setEditable] = useState();
    const [isDelete, setIsDelete] = useRecoilState(deleteAtom);

    const handleImage = () => {
        image ? setImage(false) : setImage(true);
    }

    const goModify = () => {
        navigate.goPath('/modify/'+receiptId);
    }

    useEffect(() => {
        const getReceiptDetail = async () => {
            const ulid = (location.pathname).split("/")[2];

                await receiptApi.getReceipt(ulid)
                .then(res => {
                    if (res.code === 200) {
                        if (res.data !== null) {
                            res.data.paymentAmount = (res.data.paymentAmount).toLocaleString();
                            res.data.date = utilService.formatDate(res.data.date, 'yyyy.MM.dd');
                            
                            setEditable(res.data.editable);
                            setReceiptId(res.data.reciptId);
                            setReceiptData(
                                [
                                    {title: '지불처',content: res.data.paymentPlace},
                                    {title: '날짜', content: res.data.date},
                                    {title: '적요', content: res.data.summary},
                                    {title: '금액', content: res.data.paymentAmount},
                                    {title: '대상자', content: res.data.attendees},
                                    {title: '비고', content: res.data.comment}
                                ]
                            )
                            setImageUrl(res.data.imgUrl);
                        }
                    }
                })
                .catch(error => {

                    // 에러 표시 필요 -> 200 이외도 고려 필요
                    alert(error);
                })
            
        }
        
        getReceiptDetail();
    }, [])

    return (
        <div className='detail-wrap'>
            <div>
                <Header title={headerTitleProps.detail} />
            </div>

            <div className='detail-bg'>
                {
                    receiptData && receiptData.map((data, index, receiptData) => (
                        <div key={index}>
                            <p className='detail-title'>
                                {data.title}
                            </p>
                            {
                                index === receiptData.length - 1 ?
                                <div className='detail-txt-area-wrap'>
                                    <textarea
                                        row="5"
                                        cols="30"
                                        readOnly
                                        value={data.content}
                                        className='detail-txt-area'
                                    />
                                </div>
                            :
                                <p className='detail-content'>
                                    {data.content}
                                </p>
                            }
                        </div>
                    ))
                }

                {
                    imageUrl && imageUrl.length > 2 && 
                    <div className='detail-image-wrap' onClick={() => handleImage()}>
                        <p><FontAwesomeIcon icon={faPaperclip} size='lg'/></p>
                        <p>영수증 사진 보기</p>
                    </div>
                }

                {
                    image &&
                    <div className="modal__background" onClick={() => handleImage()}>
                        <div className="modal__box">
                          
                                <img src={`${imageUrl}`}/>
    
                        </div>
                    </div>
                }
            </div>
            <div className='isconfirm_warp'>
            
                { !editable &&
                    <div>
                        <p className="text-main"> <FontAwesomeIcon icon={faCircleExclamation} /></p>
                        <p>확정된 데이터는 수정할 수 없습니다.</p>
                    </div>
                }  
                { isDelete && 
                    <div>
                        <p className="text-main"> <FontAwesomeIcon icon={faCircleExclamation} /></p>
                        <p>삭제취소 후 수정을 진행해 주세요.</p>
                    </div>
                }
                {
                    (editable && !isDelete) &&
                    <div></div>
                }
                <button 
                    className={`${(!editable || isDelete) ? 'button type2' : 'button'}`}
                    onClick={() => goModify()}
                    disabled={(!editable ||  isDelete) ? true : false}
                >
                수정하기
                </button>
            </div>
        </div>
    )
}

export default Detail;