import { useCallback } from "react"
import useErrorModal from "./useErrorModal";

import apiCodeObject from "../error/errorType";
import useModal from "./useModal";
import useNavigation from "./useNavigation";

const useFetchResult = () => {
    const { openErrorModal } = useErrorModal();
    const { showModal, closeModal } = useModal();
    const navigate = useNavigation();

    const getMessageCode = (apiMessage) => {
        const apiCode = Object.keys(apiCodeObject).find(key => apiCodeObject[key] === apiMessage);
        
        return apiCode;
    }

    const handleApiRst = useCallback((apiRst) => {
        let message;
        if (apiRst.data) {
            message = apiRst.data.msg;
        } else {
            message = apiRst.msg;
        }

        const apiCode = getMessageCode(message);

        if (apiCode === 'MAIL002' || apiCode == 'MAIL000') {
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: message,
                        evt: () => {
                            navigate.goPath('/signin');
                            closeModal();
                        }
                    }
                }
            )
        } else {
            openErrorModal(apiCode);
        }


        
        
    }) 

    return { handleApiRst }
}

export default useFetchResult;