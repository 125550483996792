import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import useModal from '../../hook/useModal';
import { useEffect} from 'react';
import ErrorModal from './ErrorModal';

const CommonPopup = () => {
    const { modal } = useModal();

    useEffect(() => {
        renderModalComponent();
    }, [modal])

    const renderModalComponent = () => {
        if (!modal) {
            return null;
        }

        if (modal.modalType === 'ALERT') {
            return <AlertModal options={modal.modalProps} />
        } else if (modal.modalType === 'CONFIRM') {
            return <ConfirmModal options={modal.modalProps} />
        } else {
            return <ErrorModal options={modal.modalProps} />
        }
    }

    return (
        <>
            {renderModalComponent()}
        </>
    )
}

export default CommonPopup;