import './SlideModal.css';
import { isModalOpenAtom } from '../../state/modalAtom';
import { useRecoilState } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faCamera, faImages, faPlus, faTrashCan, faUpload } from '@fortawesome/free-solid-svg-icons';

import useNavigation from '../../hook/useNavigation';

import {
    receiptTypeAtom,
} from '../../state/headerAtom';
import { useLocation } from 'react-router-dom';

const SlidePopup = (props) => {
    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);
    const navigate = useNavigation();
    const receiptType = useRecoilState(receiptTypeAtom)[0];
    const url =  useLocation().pathname;

    const getIcon = (type) => {
        switch (type) {
            case 'camera':
                return faCamera
            case 'gallery':
                return faImages
            case 'upload':
                return faUpload
            case 'write':
                return faPlus
            case 'confirm':
                return faCalendarCheck
            case 'delete':
                return faTrashCan
        }
    }

    const closeModal = () => {
        isOpen && setIsOpen(false);
    }
    const goAction = (type) => { 
        switch (type) {
            case 'camera':
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({callModuleType : 'accessCamera', url : url}));
                }
                break;
            case 'gallery':
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({callModuleType : 'accessGallery', url : url}));
                }
                break;
            case 'upload':
                navigate.goPath('/upload');
                break;
            case 'write':
                navigate.goPath('/write/'+receiptType);
                break;
            case 'confirm':
                props.confirm();
                break;
            case 'delete':
                closeModal();
                props.delete();
                break;
        }
    }

    return (
        <>
            <div className='popup-bg' onClick={() => closeModal()}></div>
                <div className={`slide-popup-container ${props.open ? 'open' : ''}`}>
                <div className="slide-content">                  
                    {
                        props.element.map((ele, index) => (
                            <div 
                                className='slide-popup-content-wrap'
                                key={index}
                                onClick={() => goAction(ele.type)}
                            >
                                <p className="text-main"> <FontAwesomeIcon icon={getIcon(ele.type)} size='lg'/></p>
                                <p>{ele.content}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default SlidePopup;