import { receiptTypeAtom } from '../state/headerAtom';
import { useRecoilState } from 'recoil';

const useToggle = () => {
    const [toggle, setToggle] = useRecoilState(receiptTypeAtom);

    const changeType = (type) => {
        type === 0 ? setToggle('individual') : setToggle('corporation');
        window.location.reload();
    }

    return [
        toggle,
        changeType,
    ]
}

export default useToggle;