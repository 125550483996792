import React from 'react';
import useToggle from '../../hook/useToggle';

const ToggleButton = () => {
    const [toggle, changeType] = useToggle();

    return (
        <div className="pr-2">
            <button className={`font-extrabold px-2 rounded-l-lg text-sm ${toggle === 'individual' ?
                'bg-white text-green-500' : 'bg-gray-100 text-gray-300'}`}
                onClick={() => changeType(0)}
            >
                개인
            </button>
            <button className={`font-extrabold px-2 rounded-r-lg text-sm ${toggle === 'corporation' ?
                'bg-white text-green-500' : 'bg-gray-100 text-gray-300'}`}
                onClick={() => changeType(1)}
            >
                법인
            </button>
        </div>
    )
}

export default ToggleButton;
