import { Helmet } from 'react-helmet';

const MetaTag = () => {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/react/16.6.3/umd/react.production.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/react-dom/16.6.3/umd/react-dom.production.min.js"></script>
        </Helmet>
    )
}

export default MetaTag;