import customAxiosInstance from '../axios';
import { SERVER_URL } from '../../constants/constants';
//import axios from 'axios';

//const instance = axios.axiosInstance;
const {axiosInstance} = customAxiosInstance;            // 임시 수정

export const receiptApi = {

    getReceipt: async (ulid) => {
        try {
            const response = await axiosInstance.get(`/api/receipts/${ulid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    deleteReceipt: async (ulid) => {
        try {
            const response = await axiosInstance.delete(`/api/receipts/${ulid}`)
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateReceipt: async (ulid, updatedData) => {
        try {
            const response = await axiosInstance.patch(`/api/receipts/${ulid}`, updatedData);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getReceiptList: async (requestData) => {
        try {
            const response = await axiosInstance.get(`/api/receipts/list/month/${requestData.year}/${requestData.month}`,
            {params : {
            receipts_type : requestData.receiptType,
            page_num : requestData.pageNum
            }}
            )
            return response
        } catch (error) {
            throw error;
        }
    },

    getCalendarList: async (requestData) => {
        try {
            const response = await axiosInstance.get(`/api/receipts/calendar/${requestData.year}/${requestData.month}`,
            {params : {
                receipts_type : requestData.receiptType
            }}
            )
            return response
        } catch (error) {
            throw error;
        }
    },
    
    getReceiptCount: async (year, receiptType) => {
        try {
            const response = await axiosInstance.get(`/api/receipts/list/year/${year}`,
            {params : {
                receipts_type : receiptType,
            }});
            return response;
        } catch (error) {
            throw error;
        }
    },

    confirmReceipt: async (requestData) => {
        try {
            const response = await axiosInstance.post(`/api/receipts/confirm/${requestData.year}/${requestData.month}`,
            requestData.data);
            return response;
        } catch (error) {
            throw error;
        }
    },

    cancelConfirm: async (requestData) => {
        try {
            const response = await axiosInstance.delete(`/api/receipts/confirm/cancel/${requestData.year}/${requestData.month}`,
            {data : requestData.data});
            return response;
        } catch (error) {
            throw error;
        }
    },

    writeReceipt: async (requestData) => {
        try {

            console.log('test attendees : '+typeof requestData.attendees);
            console.log('test comment : '+typeof requestData.comment);
            console.log('test confirmType : '+typeof requestData.confirmType);
            console.log('test date : '+typeof requestData.date);
            console.log('test paymentAmount : '+typeof requestData.paymentAmount);
            console.log('test paymentPlace : '+typeof requestData.paymentPlace);
            console.log('test summary : '+typeof requestData.summary);

            const response = await axiosInstance.post('api/receipts', requestData)

            console.log('test response : ' + response);
            return response;
        } catch (error) {
            throw error;
        }
    },



     writePhotoReceipt2: async (requestData) => {

        console.log(requestData.fileData);

         const files = requestData.fileData;
         const fileBlobArray = [];
         const fileArray = [];

         async function fileToBlob(file) {
           return new Promise((resolve, reject) => {
             const reader = new FileReader();

             reader.onloadend = () => {
               const blob = new Blob([reader.result], { type: file.type });
               resolve(blob);
             };

             reader.onerror = (error) => {
               reject(error);
             };

             reader.readAsArrayBuffer(file);
           });
         }

         for (let i = 0; i < files.length; i++) {
           const file = files[i];

           try {
             const blob = await fileToBlob(file);
             console.log("변환된 Blob:", blob);
             fileBlobArray.push(blob);
//             fileArray.push(file);
             console.log('---------------------1');
             console.log(JSON.stringify(fileArray[i]));
           } catch (error) {
             console.error("파일을 Blob으로 변환하는 동안 오류 발생:", error);
           }
         }

         const form = new FormData();

         // fileBlobArray를 form에 추가
         for (let i = 0; i < fileBlobArray.length; i++) {
//         for (let i = 0; i < fileArray.length; i++) {
           form.append("files", fileBlobArray[i]);
//           form.append("files", fileArray[i]);
           console.log('---------------------2');
//           console.log(JSON.stringify(fileArray[i]));
           console.log(JSON.stringify(fileBlobArray[i]));
         }

        console.log('---------------------3');
        console.log(form);

         // FormData 확인
         for (const pair of form.entries()) {
             if (pair[1] instanceof File) {
             console.log(`Key: ${pair[0]}, Value (File): ${pair[1].name}, Type: ${pair[1].type}, Size: ${pair[1].size} bytes`);
             } else {
             console.log(`Key: ${pair[0]}, Value: ${pair[1]}`);
             }
         }

         const config = {"Content-Type": "multipart/form-data"};

         try {
//           debugger;
           const response = await axiosInstance.post(
             `api/receipts/photo/${requestData.year}/${requestData.month}`, form, config)
           return response;
         } catch (error) {
           throw error;
         }
     },

     writePhotoReceipt: async (form,year,month) => {
        // FormData 확인
        for (const pair of form.entries()) {
            if (pair[1] instanceof File) {
            console.log(`Key: ${pair[0]}, Value (File): ${pair[1].name}, Type: ${pair[1].type}, Size: ${pair[1].size} bytes`);
            } else {
            console.log(`Key: ${pair[0]}, Value: ${pair[1]}`);
            }
        }

        const config = {"Content-Type": "multipart/form-data"};

        try {
            const response = await axiosInstance.post(`api/receipts/photo/${year}/${month}`, form, config)
            return response;
        } catch (error) {
            throw error;
        }
    },

    modifyPhoto: async (form, ulid) => {
        for (const pair of form.entries()) {
            if (pair[1] instanceof File) {
                console.log(`Key: ${pair[0]}, Value (File): ${pair[1].name}, Type: ${pair[1].type}, Size: ${pair[1].size} bytes`);
            } else {
                console.log(`Key: ${pair[0]}, Value: ${pair[1]}`);
            }
        }

        const config = {"Content-Type": "multipart/form-data"};
        try {
            const response = await axiosInstance.patch(`api/receipts/${ulid}/photo`, form, config);
            return response;
        } catch (error) {
            throw error;
        }
    }
//    uploadReceipt: async (requestData) => {
//        const axiosInstance1 = axios.create({
//            baseURL : `${SERVER_URL}`,
//            headers : {
//                'Access-Control-Allow-Origin' : '*',
//                'Access-Control-Allow-Headers' : 'Content-Type',
//                'Content-Type' : 'multipart/form-data',
//                'Access-Control-Allow-Credentials':"true",
//            }
//        })
//
//        console.log(requestData)
//        try {
//            const response = await axiosInstance1.post(`/api/receipts/photo/${requestData.year}/${requestData.month}`,
//            {params : {
//                files : requestData.files,
//            }});
//            return response;
//        } catch (error) {
//            console.log(error);
//        }
//    },


};

export default receiptApi;