import {useState, useEffect} from 'react';
import './Receipt.css';

import Header from "../../components/header/Header";
import { headerTitleProps } from '../../components/header/headerProps';
import { receiptTypeAtom } from '../../state/headerAtom';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import receiptApi from '../../services/receiptService/receiptApi';

import useModal from '../../hook/useModal';
import SlideModal from '../../components/modal/SlideModal';
import { isModalOpenAtom } from '../../state/modalAtom';
import { slideModalProps } from '../../components/modal/slideModalProp';
import { utilService } from '../../util/utilService';

import useNavigation from '../../hook/useNavigation';

import { fetchLoadingAtom } from '../../state/headerAtom';
const Modify = () => {
    const location = useLocation();
    const navigate = useNavigation();

    const receiptType = useRecoilState(receiptTypeAtom)[0];
    
    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);
    const [image, setImage] = useState(false);
    const [receiptData, setReceiptData] = useState([]);
    let [reqData, setReqData] = useState();
    let [imgUrl, setImgUrl] = useState();
    const [ulid, setUlid] = useState();

    const [validCheckData, setValidCheckData] = useState();

    const { showModal, closeModal } = useModal();
    const [isFetchLoading, setFetchLoading] = useRecoilState(fetchLoadingAtom); //eslint-disable-line no-unused-vars
    const selectOption = [
        '식대',
        '회식비',
        '접대비',
        '교통비',
        '비품구매',
        '간식비',
        '차량유지비',
        '기타'
    ]

    const handleImage = () => {
        image ? setImage(false) : setImage(true);
    }

    const modifyComplete = () => {
        closeModal();
        navigate.goPath("/")
    }

    const modify = async () => {
        await receiptApi.updateReceipt(ulid, reqData)
        .then(res => {
            console.log(res);
            if (res.code === 200) {
                showModal(
                    {
                        isOpen: true,
                        modalType: 'ALERT',
                        modalProps: {
                            content: '수정이 완료되었습니다.',
                            evt: modifyComplete
                        }
                    }
                )
            }
        })
        .catch(error => {
            showModal(
                {
                    modalType: 'ERROR',
                    modalProps: {
                        content: error.response.data.message,
                    }
                }
            )
        })
    }

    const modifyReceipt = async () => {
        console.log(reqData);
        const checkParam = {
            paymentPlace: '지불처',
            date: '날짜',
            summary: '적요',
            paymentAmount: '금액',
        }

        const validationResult = utilService.validCheck(reqData, checkParam);

        if (typeof validationResult === 'boolean') {
            setValidCheckData(null);

            showModal(
                {
                    isOpen: true,
                    modalType: 'CONFIRM',
                    modalProps: {
                        content: '내용을 수정하시겠습니까?',
                        evt: modify
                    }
                }
            )
        } else {
            setValidCheckData(validationResult)
        }
    }

    const changeImage = () => {
        setIsOpen(true);
    }
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setReqData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    let globalCount = 0;

    // ios 사진촬영, 갤러리선택
     const receiveModify  = async (e) => {
        setFetchLoading(true)

        const check = utilService.isJson(e.data);
        // const check = IsJson(e.data);

        if(check) {
            if(globalCount === 0){
                globalCount++;

                //String
                const receiveData = e.data;

                //Object
                const parseArray = JSON.parse(receiveData);

                let form = new FormData();

                const parseData = parseArray[0];

                const result = await utilService.parseDataToFile(parseData);
                modifyPhoto(result, form);
            }
        }
    }

    const modifyPhoto = (result, form) => {
        // 결과를 FormData에 추가
        form.append("file", result);
        
        if (location.pathname.includes('modify')) {

            receiptApi.modifyPhoto(form, location.pathname.split('/')[2])
            .then(res => {

                let responseMsg = res.data.message; 

                if (res.status === 200) {
                    responseMsg = "사진이 변경되었습니다!"
                }

                showModal(
                    {
                        modalType: 'ALERT',
                        modalProps: {
                            content: responseMsg,
                            evt: () => {
                                window.location.reload();
                            }
                        }
                    }
                )

            })
            .catch(error => {
                showModal(
                    {
                        modalType: 'ALERT',
                        modalProps: {
                            content: "사진 변경을 실패했습니다."
                        }
                    }
                )

            })
        }else {
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: "사진 변경을 실패했습니다."
                    }
                }
            )
            setFetchLoading(false);
        }
    }
    
    
    useEffect(() => {
        const getFetchData = async () => {
            const ulid = location.pathname.split('/')[2];
            
            await receiptApi.getReceipt(ulid)
            .then(res => { 
                console.log(res)
                setUlid(res.data.reciptId)
                setReceiptData([
                    { title: '지불처', content: res.data.paymentPlace },
                    { title: '날짜', content: res.data.date },
                    { title: '적요', content: res.data.summary },
                    { title: '금액', content: res.data.paymentAmount },
                    { title: '대상자', content: res.data.attendees },
                    { title: '비고', content: res.data.comment },
                    ...res.data.imgUrl !== 'N' ? 
                    [{title: '이미지 파일', content: res.data.imgUrl}] 
                    : 
                    [{title: '이미지 등록', content: res.data.imgUrl}]
                    // { title: '이미지파일', content: res.data.imgUrl}
                    // ...(res.data.imgUrl !== 'N' ? [{ title: '이미지 파일', content: res.data.imgUrl }] : []),
                ]);
                
                setReqData({
                    attendees : res.data.attendees,
                    confirmType: receiptType.toUpperCase(),
                    memberId: 0,
                    comment: res.data.comment,
                    date: res.data.date,
                    imageUrl: res.data.imgUrl,
                    paymentAmount: res.data.paymentAmount,
                    paymentPlace: res.data.paymentPlace,
                    summary: res.data.summary,
                })
                
                if (res.data.imgUrl !== 'N') {
                    setImgUrl(res.data.imgUrl);
                }
            })
            .catch(error => {
                // 에러 표시 필요 -> 200 이외도 고려 필요
                console.error(error);
            })
        }

        document.addEventListener('message', receiveModify);
        window.addEventListener('message', receiveModify);

        getFetchData();

        return () => {
            window.removeEventListener('message', receiveModify);
            document.removeEventListener('message', receiveModify);
        }
    }, [])

    return (
        <div className='detail-wrap'> 
            <div>
                <Header title={headerTitleProps.modify} />
            </div>  

            <div className='detail-bg' style={{display:'grid', gap:'3%'}}> 
                {   
                    receiptData.map((data, index) => (
                        <div key={index}>
                           <p className='detail-title' style={{ backgroundColor: "revert" }}> {data.title} </p>
                            {
                            index === 0 ?
                            <div className='input-text'>
                                <input
                                    name='paymentPlace'
                                    onChange={handleInputChange}
                                    defaultValue={data.content}
                                />
                                </div>
                            :
                            index === 1 ?
                            <div className='input-text'>
                                <input
                                    name="date"
                                    type="date"
                                    onChange={handleInputChange}
                                    defaultValue={data.content}
                                />
                            </div>
                            :
                            index === 2 ?
                            <div className='input-text'>
                                <select
                                    name="summary"
                                    onChange={handleInputChange}
                                    defaultValue={data.content}
                                >
                                    <option value="default" disabled style={{ color: "#ccc" }}>
                                        ---선택---
                                    </option>
                                    {
                                        selectOption.map((option, index) => (
                                            <option 
                                                value={option} 
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            :
                            index === 3 ?
                            <div className='input-text'>
                                <input  
                                    type="number"
                                    name="paymentAmount"
                                    defaultValue={data.content}
                                    onChange={handleInputChange}
                                />
                            </div>
                            :
                            index === 4 ?
                            <div className='input-text'>
                                <input
                                    name="attendees"
                                    defaultValue={data.content}
                                    onChange={handleInputChange}
                                />
                            </div>
                            :
                            index === 5 ?
                            <div className='detail-txt-area-wrap'>
                                <textarea
                                    name="comment"
                                    rows="3"
                                    cols="50"
                                    className='detail-txt-area'
                                    defaultValue={data.content}
                                    onChange={handleInputChange}
                                />
                            </div>
                            :
                            // index == 6 ?
                            (data.content !== 'N') ?
                            <div className='detail-image-wrap' onClick={() => handleImage()}>
                                <p><FontAwesomeIcon icon={faPaperclip} size='lg'/></p>
                                <p>영수증 사진 보기</p>
                            </div>
                            :
                            <div className='detail-image-wrap' onClick={() => changeImage()}>
                                <p><FontAwesomeIcon icon={faPaperclip} size='lg'/></p>
                                <p>영수증 사진 등록</p>
                            </div>
                            // <div></div>                         
                        }
                        
                        

                        </div>                       
                    ))
                }

                {
                    image &&
                    <div className="modal__background" onClick={() => handleImage()}>
                        <div className="modal__box">
                            
                                <img src={`${imgUrl}`}/>
                           
                                <button className='img-modify-button' onClick={() => changeImage()}>변경</button>
                          
                        </div>
                    </div>
                }
                
                

                {
                    isOpen &&
                    <SlideModal
                        open={isOpen}
                        element={slideModalProps.upload}
                    />
                }
            </div>
            <div className='modify-wrap'>
                {
                    validCheckData &&
                    <p style={{ color:"rgb(93, 176, 117)"}}>
                        {`${validCheckData}`}
                    </p>
                }
                <button className='modify-button' onClick={() => modifyReceipt()}>수정하기</button>
            </div>
        </div>
    )
}

export default Modify;