import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

/*  Member Page  */
import SignIn from '../page/member/SignIn'; //로그인
import SignUp from '../page/member/SignUp'; //회원가입
import FindId from '../page/member/FindId'; //이메일 찾기
import FindPw from '../page/member/FindPw'; //비밀번호 찾기

 /* Receipt Page+ */
import Upload from '../page/receipt/Upload';
import Write from '../page/receipt/Write';
import Detail from '../page/receipt/Detail';
import Modify from '../page/receipt/Modify';

/* Etc Page */
import Home from '../page/Home';
import DivsSlct from '../page/member/DivsSlct';
import { useRecoilState } from 'recoil';
import { memberInfoAtom } from '../state/memberAtom';
import ChangePw from '../page/member/ChangePw';

const RouteComponent = () => {
    const [bypass] = useState(["/signin","/signup","/findid","/findpw","/cmn_signup","/cmn_findid","/cmn_findpw"]);
    const [memberInfo] = useRecoilState(memberInfoAtom);
    const navigate = useNavigate();

    useEffect(() => {
      loginCheck();
    }, [])
    useEffect(() => {
      loginCheck();
    }, [memberInfo])
    
    
    const loginCheck = ()=>{
      let nowPath = window.location.pathname;
      if(bypass.findIndex(path=>nowPath === path)===-1){
        if(!memberInfo.isLogin){
            navigate("/signin") 
        }
      }
    }
    
    return (
        <Routes>

            <Route path="/signin" element={ <SignIn />} /> 
            
            <Route path="/cmn_signup" element={ <SignUp />} />

            <Route path="/cmn_findid" element={ <FindId />} />

            <Route path="/cmn_findpw" element={<FindPw />} />

            <Route path="/divsslct" element={<DivsSlct />} />

            <Route path="/" element={ <Home />} />

            <Route path="/test" element={ <Home />} />

            <Route path="/write/:receiptType" element={ <Write />} />

            <Route path="/upload" element={ <Upload />} />

            <Route path="/modify/:receiptId" element={ <Modify /> } />

            <Route path="/detail/:receiptId" element={ <Detail />} />
        
            <Route path="/change_pw" element={<ChangePw />} />
        </Routes>
    )
}

export default RouteComponent
