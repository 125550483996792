import { atom } from 'recoil';
import moment from 'moment';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

//개인, 법인
export const receiptTypeAtom = atom({
    key: 'receiptType',
    default: 'individual',
    effects_UNSTABLE: [persistAtom],
})

//영수증 타입 별 리스트 갯수
export const receiptTypeCntAtom = atom({
    key: 'receiptTypeCnt',
    default : 0
})

//총 금액
export const totalAmountAtom = atom({
    key: 'totalAmount',
    default: 0
})

//헤더 날짜 (2024년 2월)
export const dateAtom = atom({
    key: 'date',
    default: moment().format('YYYY년 M월'),
    effects_UNSTABLE: [persistAtom],
})

//달력 노출 여부
export const calendarAtom = atom({
    key: 'calendar',
    default: false
})

//통신 진행 중 spinner 표기
export const fetchLoadingAtom = atom({
    key: 'fetchLoading',
    default:false
})

//개발계 여부 확인
export const isDevAtom = atom({
    key: 'isDev',
    default:false
})