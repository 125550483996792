const errorType = 
{
    /* 로그인 */
    'Auth-0000' : '로그인에 실패했습니다.',
    // 'Auth-0001' : '비밀번호가 일치하지 않습니다.',

    'Auth-0003' : '인증메일 발송에 실패했습니다.',
    'Auth-0004' : '기 가입된 사용자입니다.',
    'Auth-0005' : '등록되지 않은 이메일입니다',
    'Auth-0007' : 'No value present',  //비밀번호 찾기 이메일 없을 경우 메시지 임시로


    'Auth-0006' : '등록된 법인 카드가 없습니다.',


    'Auth-9999' : '요청에 실패했습니다.'


}

const apiCodeObject = {
    "ERNT000": "네트워크 오류가 발생했습니다.",
    "APLY000": "패스워드 미등록",
    "APLY001": "기 가입 사용자",
    "APLY002": "등록되지 않은 이메일 입니다.",

    "PASS000": "사용할 수 있는 비밀번호 입니다.",
    "PASS001": "최소 10이상, 20자 미만의 비밀번호를 설정해주세요.",
    "PASS002": "영문자, 특수문자, 숫자의 조합으로 생성해 주세요.",
    "PASS003": "비밀번호가 수정됐습니다.",
    "PASS004": "기존 비밀번호가 일치하지 않습니다.",

    "TOKN000": "토큰 확인 됐습니다.",
    "TOKN001": "인증시간이 만료되었습니다.",
    "TOKN002": "유효한 인증이 아닙니다.",

    "ERMG000": "오류가 발생했습니다. 관리자에게 문의해주세요.",

    "MAIL000": "인증메일이 발송됐습니다.",
    "MAIL001": "인증메일 발송에 실패했습니다.",
    "MAIL002": "임시 비밀번호가 발송됐습니다.",

    "AUTH000": "사용할 수 없는 계정 입니다.",
    "AUTH001": "가입처리가 완료되지 않은 계정 입니다.",

    "ROLE000": "권한이 추가 됐습니다.",
    "ROLE001": "권한을 삭제 했습니다.",
    "ROLE002": "해당 권한을 보유하고 있지 않습니다.",
    "ROLE003": "이미 부여된 권한 입니다.",
    "LGIN000": "등록되지 않은 이메일 입니다.",
    "LGIN001": "비밀번호를 확인해 주세요."
  }

export default apiCodeObject;