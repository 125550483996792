import { format } from 'date-fns';
import heic2any from 'heic2any';

export const utilService = {

    //날짜 포멧 변환
    formatDate : (date, dateFormat) => {
        let formatDate;
        if (date !== null ||
                date !== '') {
            formatDate = format(new Date(date), dateFormat);
        }

        return formatDate;
    },


    //유효성 검사 함수
    validCheck : (model, checkParam) => {
        let result = true;

        if (model && checkParam) {
            let modelKeyArr = Object.keys(model);

            let paramKeyArr = Object.keys(checkParam);

            for (let i = 0; i < paramKeyArr.length; i++) {
                for (let j = 0; j < modelKeyArr.length; j++) {
                    if (paramKeyArr[i] === modelKeyArr[j]) {
                        if (model[paramKeyArr[i]] === '' || model[paramKeyArr[i]] === null) {
                            result = checkParam[paramKeyArr[i]] + "를(을) 입력하세요";

                            return result;
                        } else {
                            if (paramKeyArr[i] === 'email') {
                                const emailRegExp = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

                                if (!emailRegExp.test(model[paramKeyArr[i]])) {
                                    result = "이메일 형식을 확인하세요";

                                    return result;
                                }
                            }
                        }
                    }
                }
            }


            return result;
        }
    },

    // 데이터가 Json인지 판변하는 함수
    isJson : (data) => {
        try {
            JSON.parse(data);
            return true;
        } catch (e) {
            return false;
        }
    },

    // base64 -> File
    base64ToFile : (byteArrays, parseData) => {
        const blob = new Blob(byteArrays, { type : parseData});
        const fileName = 'image';
        const blobType = parseData.split('/')[1];
        const saveFileName = fileName+'.'+blobType;

        return new File([blob], saveFileName);
    },

    // base64 -> Blob
    base64ToBlob : (byteArrays, parseData) => {
        const blob = new Blob(byteArrays, { type : parseData});

        return blob;
    },

    // base64 -> ArrayBuffer (blob 변환을 위해 쪼갬)
    parseDataToByteArrays : (binaryData, sliceSize) => {
        const byteArrays = [];
        for (let offset = 0; offset < binaryData.length; offset += sliceSize) {

            const slice = binaryData.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        return byteArrays;
    },

    convertFile : async (blob, toType) => {
        const resultBlob = await heic2any({blob : blob, toType : toType});
        const convertedFile = new File([resultBlob], "test.jpg",{type:"image/jpeg", lastModified:new Date().getTime()});

        return convertedFile;
    },

    parseDataToFile : async (parseData) => {
        try {
            const sliceSize = 512;
            const binaryData = atob(parseData.base64);
            const byteArrays = this.parseDataToByteArrays(binaryData, sliceSize); 
            let appendFile;
        
            if(parseData.type === 'image/heic' || parseData.type === 'image/HEIC'){
                const blob = this.base64ToBlob(byteArrays, parseData.type);
                appendFile = await this.convertFile(blob, blob.type);
                return appendFile;
            } else {
                appendFile = this.base64ToFile(byteArrays, parseData.type);
                return appendFile;
            }
        
            } catch (error) {
                throw error;
            }
    }
}