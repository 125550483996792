import { useState } from "react";

const DivsSlct = () => {
    const [slct_card, setSlctCard] = useState([]);
    const handlerClickRadioBtn =(e) =>{
        setSlctCard(e.target.value);

    }
    const onClickCnfm =(e) =>{

    }
    return (
        <div className="divs_body">
            <h1>선택하세요.</h1>
            <p className="slct-txt">주로 사용하는 카드를 선택해주세요.</p>
            <div className="divs_contens">
                <label>
                    <input type="radio" value="1" checked={slct_card === "1"} onChange={handlerClickRadioBtn} />
                    <span>개인카드</span>
                </label>
                <label>
                    <input type="radio" value="2" checked={slct_card === "2"} onChange={handlerClickRadioBtn} />
                    <span>법인카드</span>
                </label>
               
            </div>
            <button onClick={onClickCnfm}>확인</button>
        </div>

    );
};

export default DivsSlct;