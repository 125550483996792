import useNavigation from '../../hook/useNavigation';
import { useEffect, useRef, useState } from 'react';
import './member.css';
import BackstepButton from '../../components/button/BackstepButton';
import PopupPage from './PopupPage'; // 팝업 페이지 import
import { useSearchParams } from 'react-router-dom';
import { memberApi } from '../../services/memberservice/memberapi';

import useModal from '../../hook/useModal';
import useFetchResult from '../../hook/useFetchResult';
// import usePopup from '../../hook/usePopup';

const SignUp = () => {

    const { showModal, closeModal } = useModal();
    const { handleApiRst } = useFetchResult();
    const searchParams = useSearchParams()[0];
    const [keyValue, setKeyValue] = useState(searchParams.get('key'));
    const [username, setUsername] = useState("");
    const [passwordChk, setPasswordChk] = useState("");
    const [joinInfo, setJoinInfo] = useState({ isCheck: false, isPassChk: false });
    const [passCheckResult, setPassCheckResult] = useState({ isCheck: false, msg: "" });
    const [debouncedPassword, setDebouncedPassword] = useState("");
    const navigate = useNavigation();
    const [password, setPassword] = useState("");

    const [inputValidate, setInputValidate] = useState("");

    const emailInputRef = useRef(0)

    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };
    const linkClick = (e) => {
        if(e.target.id === "findid"){

            navigate.goPath("/cmn_findid");

        } else if(e.target.id === "findpw"){

            navigate.goPath("/cmn_findpw");

        } else if(e.target.id === "signin"){

            navigate.goPath("/signin");
            
        }

    };
    const closePopup = () => {
        setPopupOpen(false);
        // navigate.goPath("/signin");
        window.close();

    };

    useEffect(() => {

        memberApi.checkKey(keyValue)
            .then(({ data: res }) => {
                let { data } = res;
                let { code, msg, email } = data;
                console.log(code, msg, email);
                setUsername(email);
                setJoinInfo({ ...joinInfo, isCheck: code === 'TOKN000', key: keyValue })
                setKeyValue(code === 'TOKN000')
                emailInputRef.current.disabled = (code === 'TOKN000');

                if (code !== 'TOKN000') {
                    setInputValidate(msg)
                }
                
            })
            .catch(err => {
                // 에러 표시 필요?
                setJoinInfo({ ...joinInfo, isCheck: false })
                setKeyValue(null)
            })
    }, [])

    useEffect(() => {
        if (passCheckResult.isCheck && passwordChk.length > 0) {
            if (password !== passwordChk) {
                setInputValidate("비밀번호가 일치하지 않습니다.")
                setJoinInfo({ ...joinInfo, isPassChk: false })
            } else {
                setInputValidate("비밀번호가 동일합니다.")
                setJoinInfo({ ...joinInfo, isPassChk: true, password })
            }
        } else {
            setJoinInfo({ ...joinInfo, isPassChk: false })
        }

    }, [password, passwordChk, passCheckResult.isCheck])

    const emailAuth = (e) => {

        if (username === '') {
            setInputValidate('이메일을 입력해주세요');
        } else {
            setInputValidate(null);
            memberApi.emailAuth({email: username})
            .then(({ data }) => {
                let type, msg;
                if (data.code === 'APLY002') {
                    type = 'ERROR';
                } else {    
                    type = 'ALERT';
                }   
                showModal(
                    {
                        modalType: type,
                        modalProps: {
                            content: data.msg,
                            evt: data.code === 'MAIL000' ? 
                                 () => {
                                     navigate.goPath('/signIn');
                                     closeModal();
                                 } : 
                                 () => {
                                    setUsername('');
                                    closeModal();
                                 }
                        }
                    }
                )
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    const DEBOUNCE_TIME = 200; // input에 200ms간 입력이 없으면 입력을 완료한 것으로 간주 

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedPassword(password);
        }, DEBOUNCE_TIME); // 새로운 타이머 설정

        return () => clearTimeout(debounce); // 이전 타이머 취소하는 클린업 함수
    }, [password]);

    useEffect(() => {
        if (!joinInfo.isCheck) return;
        //console.log(password)
        memberApi.checkPass(password)
            .then(({ data: res }) => {
                let { code, msg } = res;
                setInputValidate(msg)
                setPassCheckResult({ ...passCheckResult, isCheck: code === 'PASS000' })
            })
            .catch(err => {
                setInputValidate("비밀번호 검증중 오류가 발생했습니다.")
                //console.log(err)
            })
    }, [debouncedPassword]); // debouncedSearchValue가 변경될 때마다 api 호출

    const memberActive = (e) => {
        if (joinInfo.isCheck && joinInfo.isPassChk) {
            memberApi.memberActive(joinInfo)
                .then(({ data }) => {
                    openPopup();
                })
        } else if(!joinInfo.isCheck){
            setInputValidate("메일 인증이 올바르게 처리되지 않았습니다.")
        } else if(!joinInfo.isPassChk){
            setInputValidate("비밀번호를 다시 확인해 주세요.")
        }else{
            setInputValidate("가입 중 오류가 발생했습니다.")
        }
    };

    return (
        <div>
            <div>
                <BackstepButton />
            </div>
            <div className='main_body'>
                <div className='main_div1'>
                    <div className='member_h1'>
                        <h1>가입해요</h1>
                    </div>

                    <div className="main_div2">
                        <input className="text" placeholder="Email" value={username} onChange={(e) => { setUsername(e.target.value) }} type="email" ref={emailInputRef} />
                        {
                            joinInfo.isCheck &&
                            <>
                                <input className="text" placeholder="Password" type="password" onChange={(e) => { setPassword(e.target.value); }} ></input>
                                <input className="text" placeholder="Check Password" onChange={(e) => { setPasswordChk(e.target.value);}} type="password" />
                            </>
                        }
                    </div>
                    <p className="p3"> {inputValidate} </p>
                    {
                        joinInfo.isCheck ?
                            <button className="button" onClick={memberActive}>가입하기</button> :
                            <button className="button" onClick={emailAuth}>인증하기</button>
                    }

                    <div>
                        <p className="p3">√ 기존 등록된 이메일만 가입가능합니다. <br />√ 등록을 원하신다면 경영지원팀으로 문의주세요.</p>
                    </div>
                    <div className="side-menu">
                        <p className="p3" id="signin" onClick={linkClick}>로그인</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="findid" onClick={linkClick}>아이디 찾기</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="findpw" onClick={linkClick}>비밀번호 찾기</p>                        
                    </div>
                </div>
            </div>

            {isPopupOpen &&
                <PopupPage
                    title={'가입완료'} //팝업에 넘길 헤더명
                    message={'당신의 증빙을 쉽고 빠르게! \n 지금 바로 사용해 보세요.'} //팝업에 표시할 메시지
                    btnTex={'앱에서 진행'} //팝업 닫힘 버튼 텍스트
                    onClose={closePopup} //팝업이 닫힐 때
                />
            }

        </div>
    );
};

export default SignUp;