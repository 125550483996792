import './App.css';
import { RecoilRoot } from 'recoil';

import RouteComponent from './route/route';
import { Interceptors } from './services/interceptor';
import CommonModal from './components/modal/CommonModal';
import GlobalLoading from './components/loading/GlobalLoading';

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Interceptors>
          <GlobalLoading/>
          <RouteComponent />
          <CommonModal />
        </Interceptors>
      </RecoilRoot>
    </div>
  );
}

export default App;
