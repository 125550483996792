import './member.css';
import { useLocation } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function PopupPage({onClose, onFindPw, infoTxt}) {
  const navigate = useLocation();
  let {title, txt, btnTex, spantag} = infoTxt ?? {};

  if(infoTxt == null){
        title = '가입성공';
        txt = '당신의 증빙을 쉽고 빠르게\n지금바로 사용해보세요';
        btnTex = '앱에서 진행';
        spantag = '';
  }
  return (
    <div className="popup-overlay">
     
        <div className="popup-content">
        <div className='popup-img'>
            <p className="text-main text-5xl"> <FontAwesomeIcon icon={faCheckCircle} /></p>
        </div>
          <p className='text-2xl font-bold my-8'>{title}</p>
          <p className='popup-detail'style={{whiteSpace: "pre-line"}}>
            {txt}
           </p>
          <button className="button" onClick={onClose}>{btnTex}</button>
          <span onClick={onFindPw} >{spantag}</span>
        </div>
     
    </div>
  );
}

export default PopupPage;