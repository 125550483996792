import React, { useEffect, useState } from 'react';

import Header from '../../components/header/Header';
import { headerTitleProps } from '../../components/header/headerProps';

import { dateAtom } from '../../state/headerAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { isModalOpenAtom } from '../../state/modalAtom';
import { useRecoilState } from 'recoil';
import { fetchLoadingAtom } from '../../state/headerAtom';

import receiptApi from '../../services/receiptService/receiptApi';

import { slideModalProps } from '../../components/modal/slideModalProp';
import SlideModal from '../../components/modal/SlideModal';
import useModal from '../../hook/useModal';
import { useLocation } from 'react-router-dom';
import useNavigation from '../../hook/useNavigation';
import { utilService } from '../../util/utilService';

const Upload = () => {
    const location = useLocation();
    const [checkedList, setCheckedList] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [isFetchLoading, setFetchLoading] = useRecoilState(fetchLoadingAtom); //eslint-disable-line no-unused-vars

    const { showModal } = useModal();
    const { closeModal } = useModal();

    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);
    const date= useRecoilState(dateAtom)[0];
    const [checkCount, setCheckCount] = useState(0);
    const [uploadReceiptData, setUploadReceiptData] = useState();

    const navigate = useNavigation();
    const [resultImage, setResultImage] = useState([]);

    const viewInit = () => {
        isOpen && setIsOpen(false);
    }

    let globalCount = 0;
    let blobArray = [];
    

// ios 사진촬영, 갤러리선택
    const receiveUpload  = async (e) => {  
        setIsOpen(false);

        // setFetchLoading(true);
        const check = utilService.isJson(e.data);

        if(check){

            if(globalCount === 0){
                globalCount++;

                const receiveData = e.data;
                const parseArray = JSON.parse(receiveData);
                
                if (location.pathname === '/upload') {
                    const promises = [];

                    for (let i = 0; i < parseArray.length; i++) {
                        const parseData = parseArray[i];
                        
                        promises.push(utilService.parseDataToFile(parseData));

                    }
                    const results = await Promise.all(promises);

                    setResultImage(results);

                    const imageGroupData = [];

                    let tempIndex = 1;
                    blobArray.forEach(blob => {
                        let url = window.URL.createObjectURL(blob);
                        imageGroupData.push({
                            "date": tempIndex,
                            "imageUrl" : url,
                            "imageName": tempIndex
                        })

                        setUploadReceiptData((prevData) => ({
                            ...prevData,
                            imageData : 
                            imageGroupData
                        }));

                        tempIndex += 1;
                    });

                    setFetchLoading(false);

                }
            }
        }
    }

    // 체크박스 주석
    const checkedItemHandler = (value, isChecked) => {
        if(isChecked){
            setCheckedList((prev) => [...prev, value]);
            setCheckCount(checkCount + 1);
            return;
        }

        if(!isChecked && checkedList.includes(value)){
            setCheckedList(checkedList.filter((item) => item !== value));
            setCheckCount(checkCount - 1);
            return;
        }
        return;
    }

    const checkHandler = (e, value) => {
        setIsChecked(!isChecked);
        checkedItemHandler(value, e.target.checked);
    };

    useEffect(() => {
        window.addEventListener("message", receiveUpload);
        document.addEventListener("message", receiveUpload);

        viewInit();

        return () => {
            window.removeEventListener('message', receiveUpload);
            document.removeEventListener('message', receiveUpload);
        }

    }, [])

const uploadReceiptPhoto = async () => {

    const form = new FormData();

    // 결과를 FormData에 추가
    resultImage.forEach(newFile => {
        form.append("files", newFile);
    });

    //date
    let day;

    let year = parseInt(date.split(' ')[0], 10);
    let month = parseInt(date.split(' ')[1], 10);

    if (date.split(' ').length > 2) {
        day = parseInt(date.split(' ')[2], 10);
    }

    receiptApi.writePhotoReceipt(form, year, month)
    .then(res => {
        // 성공 처리
        if(res.status === 200){
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: res.data.message,
                        evt: () => {
                            navigate.goPath("/");

                            closeModal();
                        }
                    }
                }
            )
        }
    }).catch(error => {
        //alert(error);

    }).finally(error => {
        globalCount = 0;
    })
}

const applyBtnClick = async (e) => {
    if (!uploadReceiptData) {
        showModal(
            {
                isOpen: true,
                modalType: 'ALERT',
                modalProps: {
                    content: '영수증을 추가해주세요',
                }
            }
        )
    } else {
        showModal(
            {
                isOpen: true,
                modalType: 'CONFIRM',
                modalProps: {
                    content: '영수증을 등록하시겠습니까?',
                    evt: uploadReceiptPhoto
                }
            }
        )
    }
    
}

    return (
        <div  className='detail-wrap' style={{ padding: "0 0 125px 0" }}>
            <div>
                <Header title={headerTitleProps.upload} />
            </div>
            <div className='detail-bg' style={{display:'grid', gap:'3%'}}>
                {
                    uploadReceiptData ?
                    Object.keys(uploadReceiptData).map((date, dateIndex) => (
                        <div key={dateIndex}>

                            <ul className='content-ul'>
                                {
                                    uploadReceiptData[date].map((item, index) => (

                                        <li
                                            className='content-li-list'
                                            key={index}
                                        >
                                            <div className='flex-none ml-3'>
                                            <input
                                                style={{"display": "none"}}
                                                type="checkbox"
                                                checked={checkedList.includes(item)}
                                                onChange={(e) => checkHandler(e, item)}
                                            />
                                            </div>
                                            <span className='content-li-amount w-1/4'>
                                                <img className = 'max-h-12' src = {`${item.imageUrl}`}  />
                                            </span>

                                            <span className='content-li-amount text-gray-500'>
                                                파일 번호: {item.imageName}
                                            </span>

                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )) : <p className='none-wrap'>영수증을 추가해주세요</p>
                }
            </div>

            <div className='isconfirm_warp'>
                <div>
                    <p className="text-main"> <FontAwesomeIcon icon={faCircleExclamation} /></p>
                    <p>최대 5장까지 업로드 가능합니다.</p>
                </div>
                <button
                    className="button"
                    style={{margin:"5% 0%", backgroundColor : uploadReceiptData ? '#00A572' : '#D3D3D3'}}
                    onClick={applyBtnClick}
                    disabled={uploadReceiptData ? false : true}
                >
                등록하기
                </button>
            </div>

            <div>
            {
                <div className='category-btn-wrap'>
                    <div>
                        {
                            isOpen &&
                            <SlideModal
                                open={isOpen}
                                element={slideModalProps.upload}
                            />
                        }
                    </div>
                </div>
            }
        </div>

        </div>

    )
}

export default Upload;