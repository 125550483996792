import useNavigation from '../../hook/useNavigation';
import '../../common.css';
import { useLocation } from 'react-router-dom';

import { isModalOpenAtom } from '../../state/modalAtom';
import { useRecoilState } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

const BackstepButton = (props) =>{
    const title = props.title;
    const navigate = useNavigation();
    const location = useLocation();

    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);

    const openCategory = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
    }

    const backStep = () => {
        navigate.goPath(-1);
    }
    const sRout = location.pathname;
    return (
        <div className={` ${sRout.startsWith('/cmn_') ? '' :'main-menu' }`}>
               <FontAwesomeIcon className='back-img' icon={faChevronLeft} onClick={backStep} size='lg' color='#ffffff'/>
                <p className='menu-title'>{title}</p>
            {
                location.pathname == '/upload' &&
              
                <p className="text-white text-xl menu-img"><FontAwesomeIcon className='' onClick={openCategory} icon={faPlus} size='lg'/></p>
                
            } 
        </div>    
       
    )
}

export default BackstepButton;