export const slideModalProps = {
    home : [
        {
            content: '전자등록',
            type: 'upload'
        },
        {
            content: '수기기입',
            type: 'write'
        },
        {
            content: '삭제하기',
            type: 'delete'
        },
        {
            content: '확정하기',
            type: 'confirm'
        }
    ],

    corporation_home : [
        {
            content: '수기기입',
            type: 'write'
        },
        {
            content: '삭제하기',
            type: 'delete'
        },
        {
            content: '확정하기',
            type: 'confirm'
        }
    ],

    upload : [
        {
            content: '사진촬영',
            type: 'camera'
        },
        {
            content: '갤러리',
            type: 'gallery'
        }
    ]
}
