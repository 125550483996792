import BackstepButton from '../button/BackstepButton';
import HomeHeader from './HomeHeader';
import { useLocation } from 'react-router-dom';

import MetaTag from '../MetaTag';

const Header = (props) => {
    const location = useLocation();
    
    return (
        <div>
            <MetaTag />
            {
                location.pathname === `${'/'}` ? 
                <HomeHeader /> : <BackstepButton title={props.title} />
            }
        </div>
    )
}

export default Header;