import { atom } from 'recoil';

export const isModalOpenAtom = atom({
    key: 'isOpen',
    default: false
});

export const modalStateAtom = atom({
    key: 'modalState',
    default: null
})

export const isSideMenuAtom = atom({
    key: 'isSideOpen',
    default: false
})