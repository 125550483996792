import React from 'react';
import './SideMenu.css';
import { useRecoilState } from 'recoil';
import { isSideMenuAtom } from '../../state/modalAtom';
import { memberInfoAtom } from '../../state/memberAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faLock, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { memberApi } from '../../services/memberservice/memberapi';
import { Link } from 'react-router-dom';

const SideMenu = () => {
  const [isSideOpen, setIsSideOpen] = useRecoilState(isSideMenuAtom);
  const [memberInfo, setMemberInfo] = useRecoilState(memberInfoAtom);
  
  const openSideMenu = () => {
    isSideOpen ? setIsSideOpen(false) : setIsSideOpen(true);
  }

  const closeSideMenu = () => {
    setIsSideOpen(false);
  }

  const logout = async ()=>{
    let {refreshToken} = memberInfo;
    await memberApi.doLogout(refreshToken)
    setMemberInfo({isLogin:false})
    setIsSideOpen(false);
  }

  const menuList = [
    {title:'비밀번호 변경',url:'/change_pw',icon:faLock},
  ]

  return (
    <>
      {
          isSideOpen && 
        <div className='popup-bg' style={{'backgroundColor':'rgb(0, 0, 0, 0.4)'}} onClick={() => closeSideMenu()}></div>
      }
      <div className={`sidebar ${isSideOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={openSideMenu}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        <div className="user-info">
          <div>
            {/* <img src="" alt="User Avatar" className="avatar" /> */}
            <FontAwesomeIcon icon={faCircleUser} size='2x' color='white'/>
          </div>
          <div className="user-details text-sm text-gray-300" style={{margin:'10px'}}>
            <div className='text-xl text-white font-extrabold'>{memberInfo.name}</div>
            <div className='text-sm text-white'>{memberInfo.email}</div>
          </div>
        </div>
        <ul>
          {
            menuList.map(({title,url,icon},idx)=>
              <Link to={url} key={idx}><li><FontAwesomeIcon icon={icon}/> {title}</li></Link>
            )
          }
        </ul>
        <button className="logout-button font-extrabold px-2 text-sm text-gray-300" onClick={logout}>
          <FontAwesomeIcon icon={faRightFromBracket} /> 로그아웃
        </button>
      </div>
    </>
  );
};

export default SideMenu;