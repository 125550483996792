import useNavigation from '../../hook/useNavigation';
import { useEffect, useState, useRef } from 'react';
import './member.css';
import BackstepButton from '../../components/button/BackstepButton';
import PopupPage from './PopupPage'; // 팝업 페이지 import
import { useLocation } from 'react-router-dom'
import { memberApi } from '../../services/memberservice/memberapi';
import { utilService } from '../../util/utilService';

import useFetchResult from '../../hook/useFetchResult';

import useModal from '../../hook/useModal';

const FindPw = () => {
    const { handleApiRst } = useFetchResult();
    const navigate = useNavigation();
    const {state} = useLocation();
    const [email, setEmail] = useState("");
    const [infoTxt, setInfoTxt] = useState({title:null, txt:null, btnTex:null, spantag:null})
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");

    const [inputValidate, setInputValidate] = useState("");
    const [reqData, setReqData] = useState();

    const { showModal, closeModal } = useModal();
    const emailRef = useRef(null);
    // const onChangValdate = (e) => {

    //     if(e.target.id === "email"){

    //         setEmail(e.target.value);
    //         const emailRegExp = /^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;

    //         !emailRegExp.test(email) ? setInputValidate("이메일 형식을 확인해주세요.") : setInputValidate("");
           
    //     } else if(e.target.id === "password"){

    //         setPassword(e.target.value);
            
       
    //     } else if(e.target.id === "passwordCheck"){
         
    //         setPasswordCheck(e.target.value);
           
    //     } 
    // }
    
    const onChangeValidate = (e) => {
        const { id, value } = e.target;
        
        setReqData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }
    
    const findPwClick = () => {
        const checkParam = {
            email : '이메일'
        }

        const validationResult = utilService.validCheck(reqData, checkParam);

        if (typeof validationResult === 'boolean') {
            setInputValidate(null);

            memberApi.emailTempPass(reqData).then(({data})=>{
                let type;
                if (data.code === 'APLY002') {
                    type = 'ERROR';
                } else {
                    type = 'ALERT';
                }

                showModal(
                    {
                        modalType: type,
                        modalProps: {
                            content: data.msg,
                            evt: data.code === 'MAIL002' ? 
                                 () => {
                                     navigate.goPath('/signIn');
                                     closeModal();
                                 } : 
                                 () => {
                                    closeModal();
                                    emailRef.current.value = '';
                                 }
                        }
                    }
                )
                }).catch((error) => {
                    console.log(error);
                })         
        } else {
            setInputValidate(validationResult);
        }
    }
   
    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };
    const linkClick = (e) => {
        if(e.target.id === "signin"){
            navigate.goPath("/signin");
        } else if(e.target.id === "findid"){
            navigate.goPath("/cmn_findid");
        } else if(e.target.id === "signup"){
            navigate.goPath("/cmn_signup");
        }
    
    };
    const closePopup = () => {
        setPopupOpen(false);
        navigate.goPath("/signin");

    };
    
    useEffect(() => {
        setEmail(state?.paramEmail);

        setReqData({
            email: ''
        })
    }, [])
    
    
    return(
        <div>
            <div>
                <BackstepButton/>
            </div>
            <div className='main_body'>
                <div className="main_div1">
                    <div className='member_h1'>    
                        <h1>비밀번호 찾기</h1>
                    </div>
                
                    <div className="main_div2">
                        <input placeholder="Email" id="email" onChange={onChangeValidate} value={email} type="email" ref={emailRef}/>
                        {/* <input placeholder="Password"  id="password" type="password"  onChange={onChangValdate} />
                        <input placeholder="Check Password" onChange={onChangValdate} id="passwordCheck" type="password"/> */}
                    </div>
                    <p className='valid'> {inputValidate}</p>
                    <button className="button" onClick={findPwClick}>이메일 인증하기</button>
                    <div className="side-menu">
                        <p className="p3" id="signin" onClick={linkClick}>로그인</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="findid" onClick={linkClick}>아이디 찾기</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="signup" onClick={linkClick}>회원가입</p>
                    </div>
                </div>    
            </div>
        
            {isPopupOpen && 
                <PopupPage 
                    // title={'변경완료'} //팝업에 넘길 헤더명
                    // message={'비밀변호 변경이 완료되었습니다.'} //팝업에 표시할 메시지
                    // btnTex={'로그인'} //팝업 닫힘 버튼 텍스트
                    infoTxt={infoTxt}
                    onClose={closePopup} //팝업이 닫힐 때
                />
            }
        
        </div>
    );
};

export default FindPw;