import useNavigation from '../../hook/useNavigation';
import { useEffect, useState, useRef } from 'react';
import { memberApi } from '../../services/memberservice/memberapi';
import { memberInfoAtom } from '../../state/memberAtom';
import { useRecoilState } from 'recoil';
import './member.css';
import { utilService } from '../../util/utilService';
import useFetchResult from '../../hook/useFetchResult';
import useModal from '../../hook/useModal';

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [memberInfo, setMemberInfo] = useRecoilState(memberInfoAtom)

    const [reqData, setReqData] = useState();

    const navigate = useNavigation();
    const { handleApiRst } = useFetchResult();

    const { showModal, closeModal} = useModal();
    const emailRef = useRef(null);
    const passRef = useRef(null);
    const linkClick = (e) => {

        if(e.target.id === "findid"){
        navigate.goPath("/cmn_findid");
        } else if(e.target.id === "findpw"){
        navigate.goPath("/cmn_findpw");
        } else if(e.target.id === "signup"){
        navigate.goPath("/cmn_signup");
        }
       
    };

    const [inputValidate, setInputValidate] = useState("");

    useEffect(() => {
        setReqData({
            email: "",
            password: "",
        })
    }, [])

    const onChangeValidate = (e) => {
        const { id, value } = e.target;
        
        setReqData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    const signInClick = () => {
        const checkParam = {
            email: '이메일',
            password: '비밀번호',
        }

        const validationResult = utilService.validCheck(reqData, checkParam);
        if (typeof validationResult === 'boolean') {
            setInputValidate(null);
            console.log(reqData);
            memberApi.doLogin(reqData)
            .then(({data}) => {
                console.log(data);
                let {accessToken, refreshToken, email, name} = data;
                setMemberInfo({isLogin:true, accessToken ,refreshToken, email, name})
                navigate.goPath("/")
            }).catch(({response}) => {
                showModal(
                    {
                        modalType: 'ERROR',
                        modalProps: {
                            content: response.data.msg,
                            evt: () => {
                                emailRef.current.value = '';
                                passRef.current.value = '';
                                closeModal();
                            }
                        }
                    }
                )
            });
            
        } else {
            setInputValidate(validationResult)
        }
    }
    
    return (
        <div className='main_body'>
            <div className='main_div1'>
                <div>   
                    <img alt="logo" src="../../pub/asset/image/wiseExpendi_Logo.png" />
                </div>
               
                <div className="main_div2">
                    <input placeholder="Email" onChange={onChangeValidate} id="email" type="email" ref={emailRef} />
                    <input placeholder="Password" onChange={onChangeValidate} id="password" type="password" ref={passRef} />
                    
                </div>
                <p className='valid'> {inputValidate}</p>
                <button className="button" onClick={signInClick}>로그인 </button>
                <div className="side-menu">
                    <p className="p3" id="findid" onClick={linkClick}>아이디 찾기</p>
                    <p className="p3"> | </p>
                    <p className="p3" id="findpw" onClick={linkClick}>비밀번호 찾기</p>
                    <p className="p3"> | </p>
                    <p className="p3" id="signup" onClick={linkClick}>회원가입</p>
                </div>
                    
            </div>
                
        </div>
        
    );
};

export default SignIn;