import ToggleButton from '../button/ToggleButton';

import {
    receiptTypeCntAtom,
    totalAmountAtom,
    dateAtom,
    calendarAtom,
    isDevAtom
} from '../../state/headerAtom';
import { isModalOpenAtom,isSideMenuAtom } from '../../state/modalAtom';
import { useRecoilState } from 'recoil';

import CalendarComponent from '../calendar/CalendarComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import SideMenu from '../sidemenu/SideMenu';
import useModal from '../../hook/useModal';
import { deleteAtom, confirmAtom } from '../../state/HomeAtom';
import { info } from 'autoprefixer';
const Header = () => {
    const receiptCnt = useRecoilState(receiptTypeCntAtom)[0]; 
    const totalAmount = useRecoilState(totalAmountAtom)[0];
    const date= useRecoilState(dateAtom)[0];
    const [showCalendar, setShowCalendar] = useRecoilState(calendarAtom);
    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);
    const [isDev, setIsDev] = useRecoilState(isDevAtom); 
    const [isSideOpen, setIsSideOpen] = useRecoilState(isSideMenuAtom);
    const { showModal } = useModal();
    const [isConfirm, setIsConfirm] = useRecoilState(confirmAtom);


    //TODO 개발 여부 전역 추가 처리 
    window.location.hostname.includes('dev') ? setIsDev(true) : setIsDev(false);

    const openCategory = () => {

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; //당월
        const rctxMonth = parseInt(date.split(' ')[1], 10); //헤더 월

        if(isConfirm && rctxMonth === currentMonth){
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content:'확정취소 후 진행가능합니다.'
                    }
                }
            )
          
        } else {
            isOpen ? setIsOpen(false) : setIsOpen(true);
        }
        
    }
 
    const openCalendar = () => {
        !showCalendar && setShowCalendar(true); 
    }

    const openSideMenu = () => {
        isSideOpen ? setIsSideOpen(false) : setIsSideOpen(true);
    }

    return (
        <>
            <SideMenu/>
            <div>
                <div className="bg-main text-white text-left pb-3 p-4 list-header">
                    <div id="first-header" className="flex pb-4">
                        <div className='w-50'>
                            <FontAwesomeIcon icon={faBars} onClick={openSideMenu} size='lg'/>
                        </div>
                        <div className="flex w-50 justify-end">
                            <div className="flex justify-center items-center">
                                <ToggleButton />
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faPlus} onClick={openCategory} size='lg'/>
                            </div>
                        </div>
                    </div>   

                    <div id="second-header" className="flex items-center">
                        <div
                            className="text-2xl font-semibold tracking-tighter"
                            onClick={() => openCalendar()}
                        >
                            {date} {isDev && <p className='inline'>: 개발</p>}
                        </div>   
                        <div className="font-semibold mg-left">
                            <div className="text-xl tracking-tighter text-right">{receiptCnt}건</div>
                            <div className="text-xl tracking-tighter text-right">{totalAmount}원</div>
                        </div>
                    </div>
                </div>

                {
                    showCalendar && <CalendarComponent />
                }
            </div>
        </>
    )
    
}

export default Header;