import { useState, useEffect, useRef } from 'react';
import './member.css';
import PopupPage from './PopupPage'; // 팝업 페이지 import
import useNavigation from '../../hook/useNavigation';
import { memberApi } from '../../services/memberservice/memberapi';
import { utilService } from '../../util/utilService';
import useFetchResult from '../../hook/useFetchResult';
import useModal from '../../hook/useModal';

const FindId = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [inputValidate, setInputValidate] = useState("");
    const [email, setEmail] = useState("");

    const navigate = useNavigation();
    const [reqData, setReqData] = useState();
    const { handleApiRst } = useFetchResult();

    const { showModal, closeModal} = useModal();
    const nameRef = useRef(null);
    const numberRef = useRef(null);
    // const { handleApiRst } = useFetchResult();
    // const onChangValidate = (e) => {

    //     if(e.target.id === "name"){

    //         setName(e.target.value);
    //         const regex = /^[가-힣]+$/;

    //         name.length === 0 ? setInputValidate("이름을 입력해주세요.") : (!regex.test(name) ? setInputValidate("이름을 한글로 입력해주세요.") : setInputValidate(""));

    //     } else if(e.target.id === "number"){
    //         setNumber(e.target.value);

    //         const regexNum = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    //         number.length === 0 ? setInputValidate("휴대폰번호를 입력해주세요.") : (!regexNum.test(number) ? setInputValidate("휴대폰번호는 숫자만 입력가능합니다.") : setInputValidate(""));
        
    //      } 
    // };

    useEffect(() => {
        setReqData({
            name: '',
            number: ''
        })
    }, [])

    const onChangeValidate = (e) => {
        const { id, value } = e.target;
        
        setReqData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }
    
    const onCkickFindid = () => {
        const  checkParam = {
            name : '이름',
            number : '전화번호'
        }

        const validationResult = utilService.validCheck(reqData, checkParam);

        if (typeof validationResult === 'boolean') {
            setInputValidate(null);

            memberApi.emailState(reqData)
            .then((res) => {
                let type;
                if (res.data.code === 'APLY002') {
                    type = 'ERROR';
                } else {
                    type = 'ALERT';
                }

                showModal(
                    {
                        modalType: type,
                        modalProps: {
                            content: res.data.code === 'APLY002' ? res.data.msg : res.data.email,
                            evt: res.data.code  === 'APLY002' ? 
                                 () => {
                                    nameRef.current.value = '';
                                    numberRef.current.value = '';
                                    closeModal();
                                 } : 
                                 () => {
                                    navigate.goPath('/signin');
                                    closeModal();
                                 }
                        }
                    }
                )
            })
            .catch(error => {
                console.log(error);
                showModal(
                    {
                        modalType: 'ERROR',
                        modalProps: {
                            content: error.response.data.message,
                            evt: () => {
                                nameRef.current.value = '';
                                numberRef.current.value = '';
                                closeModal();
                            }
                        }
                    }
                )
            });
            
        } else {
            setInputValidate(validationResult)
        }
    }
    
    const linkClick = (e) => {
        if(e.target.id === "signin"){
            navigate.goPath("/signin");
        } else if(e.target.id === "findpw"){
            navigate.goPath("/cmn_findpw");
        } else if(e.target.id === "signup"){
            navigate.goPath("/cmn_signup");
        }
        
    };
    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };
    
    const closePopup = () => {
        setPopupOpen(false);
        navigate("/signin");

    };
    const findPw = () => {
        setPopupOpen(false);
        navigate("/cmn_findpw", {state : {paramEmail:email}});

    }
    return(
        <div>
            <div className='main_body'>
                <div className='main_div1'>
                    <div className='member_h1'>   
                        <h1>이메일 찾기</h1>
                    </div>
                   
                    <div className="main_div2">
                        <input id="name"  placeholder="Name" onBlur={onChangeValidate} type="text" ref={nameRef} />
                        <input id="number"  placeholder="Number" onBlur={onChangeValidate} type="number" ref={numberRef} />
                    </div>
                    <p className='valid'> {inputValidate}</p>
                    <button className="button" onClick={onCkickFindid}>이메일 찾기</button>
                    <div className="side-menu">
                        <p className="p3" id="signin" onClick={linkClick}>로그인</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="findpw" onClick={linkClick}>비밀번호 찾기</p>
                        <p className="p3"> | </p>
                        <p className="p3" id="signup" onClick={linkClick}>회원가입</p>
                    </div>
                </div>
                
            </div>
            
            {isPopupOpen && 
                <PopupPage 
                    onClose={closePopup} //팝업이 닫힐 때
                    onFindPw={findPw}   //비밀번호 찾기
                    infoTxt={
                        {
                            title : '아이디 찾기',
                            txt : `${email}`,
                            btnTex : '홈으로',
                            spantag : '비밀번호 찾기'
                        }
                    }
                />
            }
        </div>
    );
};

export default FindId;