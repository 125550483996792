let SERVER_URL;

const hostname = window.location.hostname;

if (hostname.includes('localhost')) {
    SERVER_URL = "http://localhost:8080/";
} else if (hostname.includes('dev')) {
    SERVER_URL = "https://dev.wiselabpms.co.kr/";   
} else {
    SERVER_URL = "https://wiselabpms.co.kr/"
}

export { SERVER_URL };