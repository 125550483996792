import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist();

//제출 여부
export const confirmAtom = atom({
    key: 'confirm',
    default: false
})

//영수증 데이터
export const receiptDataAtom = atom({
    key: 'receiptData',
    default: []
})

//삭제 여부
export const deleteAtom = atom({
    key: 'delete',
    default: false,
    effects_UNSTABLE: [persistAtom],
})
