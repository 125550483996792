import  customAxiosInstance from './axios';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { fetchLoadingAtom } from '../state/headerAtom';
import { useRecoilState } from 'recoil';

const {axiosInstance} = customAxiosInstance;
const Interceptors = ({children})=>{
    const navigate = useNavigate();
    const [isFetchLoading, setFetchLoading] = useRecoilState(fetchLoadingAtom);

    axiosInstance.interceptors.request.use(
        (config) => {
            let memberInfo = localStorage.getItem('memberInfo');
            if(memberInfo!=null){
                config.headers['Authorization'] = `Bearer ${JSON.parse(memberInfo).accessToken}`;
            }
            if(config.fetchLoading !== false){
                setFetchLoading(true)
            }
            return config;
        },

        (error) => {
            console.log(error);
            return Promise.reject(error);
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => {
            setFetchLoading(false)
            return response;
        },

        async (error) => {
            setFetchLoading(false)
            const resetAndMove = ()=>{
                localStorage.removeItem('memberInfo')
                navigate('/signin')
                return Promise.reject(error);
            }
            if(error.response?.status === 405 || error.response?.status === 403){
                let {baseURL, headers} = error.config; 
                let localMemberInfo = localStorage.getItem('memberInfo');
                let returnIns = null;
                if(localMemberInfo!=null){
                    let memberInfo = JSON.parse(localMemberInfo)
                    await axios.create({baseURL, headers}).post("/login/token", {refreshToken:memberInfo.refreshToken})
                    .then(({data:{data:accessToken}})=>{
                        localStorage.setItem('memberInfo', JSON.stringify({...memberInfo, accessToken}))
                        error.response.config.headers['Authorization'] = `Bearer ${accessToken}`;
                        returnIns = axiosInstance(error.response.config);
                    })
                    .catch(err=>{
                        returnIns = resetAndMove()
                    })
                    return returnIns;
                }else{
                    return resetAndMove();
                }
            }else if(error.code === 'ERR_NETWORK'){
                error.response = {code : "ERNT000", msg : "네트워크 오류가 발생했습니다."}
                return Promise.reject(error);
            } else if (!error.response.status) {
                error.response = {code : "ERNT000", msg : "네트워크 오류가 발생했습니다."}
                return Promise.reject(error);
            }else{
                return Promise.reject(error);
            }
        }
    )

    return children;
}

export default axiosInstance;
export {Interceptors};