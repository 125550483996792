import { useRecoilState } from 'recoil';
import { modalStateAtom } from '../state/modalAtom';

const useModal = () => {
    const [modal, setModal] = useRecoilState(modalStateAtom)

    const showModal = ({isOpen, modalType, modalProps}) => {
        setModal({isOpen, modalType, modalProps});
    }

    const closeModal = () => {
        setModal(null);
    }

    return {
        modal,
        showModal,
        closeModal
    }
}

export default useModal;