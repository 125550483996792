import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Calendar from 'react-calendar';
import './Calendar.css';

import { useRecoilState } from 'recoil';
import { calendarAtom, dateAtom } from '../../state/headerAtom';

import { receiptTypeAtom } from '../../state/headerAtom';

import { utilService } from '../../util/utilService';

import receiptApi from '../../services/receiptService/receiptApi';
const CalendarComponent = () => {

    const [date, setDate] = useRecoilState(dateAtom);
    const receiptType = useRecoilState(receiptTypeAtom)[0];
    const [value, onChange] = useState(new Date());
    const [showCalendar, setShowCalendar] = useRecoilState(calendarAtom);
    const [dayList, setDayList] = useState([]);
    const monthOfActiveDate = utilService.formatDate(value, 'yyyy-MM-dd')
    const [activeMonth, setActiveMonth] = useState(monthOfActiveDate);    
    const [calendarType, setCalendarType] = useState('month');

    useEffect(() => {
        const fetchData = async () => {
            const year = activeMonth.split('-')[0];
            const month = activeMonth.split('-')[1];

            const requestData = {
                year: year,
                month: month,
                receiptType: receiptType,
            }

            await receiptApi.getCalendarList(requestData)
                .then(res => {
                    const resData = res.data.data;
        
                    const newDayList = [...dayList];
        
                    resData.forEach(item => {
                        newDayList.push(item);
                    });
        
                    setDayList(newDayList);
                })
                .catch(error => {
                    throw new Error('Bad Request')
            });
        }

        fetchData();
    }, [activeMonth])

    const addContent = ({ date }) => {
        const html = [];
    
        if (dayList.length > 0) {
            if (dayList.find((x) => x === moment(date).format('YYYY-MM-DD'))) {
                html.push(<div key={moment(date).format('YYYY-MM-DD')} className="dot"></div>);
            }else {
                html.push(<div className="dot invisible"></div>);
            }
            return (
                // TODO : 균형 안 맞아서 임시 방편. CSS 변경 필요 
                <div className="flex justify-center items-center absoluteDiv">
                    {html}
                </div>
            );
        }
    }

    const getActiveMonth = (activeStartDate) => {
        const newActiveMonth = moment(activeStartDate).format('YYYY-MM');
        setActiveMonth(newActiveMonth);
    };

    const selectDate = (date) => {
        setDate(moment(date).format('yyyy년 M월 D일'))

        showCalendar && setShowCalendar(false);
    }

    const changeCalendar = () => {
        calendarType === 'month' ? setCalendarType('year') : setCalendarType('month');
    }

    const selectMonth = (activeStartDate) => {
        setDate(moment(activeStartDate).format('yyyy년 M월'));

        showCalendar && setShowCalendar(false);
    }

    const closeCanlendar = () => {
        showCalendar && setShowCalendar(false);
    }

    return (
        <div>
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10">
                <div className="bg-black opacity-50 absolute w-full h-full" onClick={() => closeCanlendar()}></div>
                    <div className="bg-white p8 rounded shadow-md relative">
                        <Calendar
                            locale="ko"
                            onChange={onChange}
                            formatDay={(locale, date) => moment(date).format("D")}
                            value={value}
                            next2Label={null}
                            prev2Label={null}
                            
                            tileContent={calendarType === 'month' && addContent}
                            showNeighboringMonth={false}
                            onActiveStartDateChange={({ activeStartDate }) =>
                                getActiveMonth(activeStartDate)
                            }
                            view={calendarType}
                            onClickDay={selectDate}
                            onClickMonth={selectMonth}
                        />
                        <div className='h-20 flex justify-center items-center'>
                            <button
                                className="button"
                                onClick={() => changeCalendar()}>
                                {
                                    calendarType === 'month' ? `${'월별'}` : `${'일별'}`
                                }
                            </button>
                        </div>
                    </div>
            </div>
        </div>
        
        
    )
}

export default CalendarComponent