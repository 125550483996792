import customAxiosInstance from "../axios"

const {axiosInstance} = customAxiosInstance;
export const memberApi = {
    doLogin : (requestData)=>{
        let formData = new FormData();
        formData.append('username',requestData.email)
        formData.append('password',requestData.password)
        return axiosInstance.post("/login",formData)
    },
    doLogout : (refreshToken)=>{
        return axiosInstance.post("/doLogout", {refreshToken})
    },
    emailAuth : (emailAuthData) => {
        return axiosInstance.post("/api/member/email", emailAuthData)
    },
    checkKey : (key) =>{
        return axiosInstance.post("/api/member/token/check", {token:key})
    },
    checkPass : (password) =>{
        return axiosInstance.post("/api/member/pass/check", {password}, {fetchLoading : false})
    },
    memberActive : ({key,password})=>{
        return axiosInstance.post("/api/member/email/auth", {key,memberPass : password})
    },
    emailState : (requestData)=>{
        debugger;
        return axiosInstance.get("/api/member/email", {params:{name: requestData.name ,cellPhone:requestData.number}})
    },
    emailTempPass : (requestData)=>{
        return axiosInstance.post("/api/member/email/pass/temp" , {email:requestData.email})
    },
    changePw : ({password,newPassword})=>{
        return axiosInstance.put("/api/member/pass", {password,newPassword})
    }
}