import './Modal.css';
import useModal from '../../hook/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const ConfirmModal = (props) => {
    const { closeModal } = useModal();
    const option = props.options;
    // const text = option.content;
    // const content = text.replace(/니다\./g, "니다.\n");
    // console.log(text)


    return (
        <div className='popup-overlay'>
            <div className="popup-content">
                <div className='popup-img'>
                    <p className="text-main text-5xl"> <FontAwesomeIcon icon={faCircleQuestion} /></p>
                </div>

                <p className='popup-detail'>
                    {option.content}
                </p>

                <div className='popup-button-wrap'>
                    <button 
                        onClick={() => closeModal()}
                        className='popup-cancel-button'
                    >취소
                    </button>
                    <button 
                        onClick={option.evt}
                        className='popup-confirm-button'
                    >확인
                    </button>
                </div>
                
            </div>
        </div>
    )
}

export default ConfirmModal;