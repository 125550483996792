import './Modal.css'
import useModal from '../../hook/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const AlertPopup = (props) => {
    const { closeModal } = useModal();
    const option = props.options;

    // const content = option.content.split('.').join('\n');

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='popup-img'>
                    <p className="text-main text-5xl"> <FontAwesomeIcon icon={faCheckCircle} /></p>
                </div>

                <p className='popup-detail'>
                    {option.content}
                </p>

                <button
                    onClick={option.evt ? option.evt : closeModal}
                    className='popup-button'
                >
                    확인
                </button>
            </div>
        </div>
    )
}

export default AlertPopup;