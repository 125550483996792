import axios from 'axios';
import { SERVER_URL } from '../constants/constants';

const axiosInstance = axios.create({
    baseURL : `${SERVER_URL}`,
    headers : {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers' : 'Content-Type',
        //'Content-Type' : 'application/json',
        'Access-Control-Allow-Credentials':"true",
    }
})
axiosInstance.defaults.withCredentials = true;

 const customTransformRequest = (data, headers) => {
   return data;
 };

 const axiosMultiPartInstance = axios.create({
     baseURL : `${SERVER_URL}`,
     headers : {
       'Content-Type' : 'multipart/form-data',
     },
     transformRequest: customTransformRequest,
 })

 export default {axiosInstance, axiosMultiPartInstance}
